// TODO: Prettier space-between-blocks
// TODD: Prettier ::after
// TODO: Prettier spaces between classes
// TODO: sort out repeated classes

.main-grid {
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;

	main {
		transition: transform 400ms ease;

		&.show-character-tiles {
			transform: translateX(-100%);
		}

		&,
		.main-grid-wrapper {
			height: 100%;
		}
	}

	.main-grid-wrapper {
		position: relative;
		width: 100%;
	}
}

.character-move-cards,
.character-tiles {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.character-move-cards {
	left: 0;
	padding: 1rem;

	.col-character-move-card {
		--bs-gutter-x: 1rem;
		margin-bottom: var(--bs-gutter-x);
	}

	.card {
		--bs-card-border-radius: 3px;
		--bs-border-color-translucent: rgba(0, 0, 0, 0.1);
	}
}

.character-tiles {
	left: 100%;

	.row-character-tile,
	.col-character-tile {
		--bs-gutter-x: 0.5rem;
	}

	.col-character-tile {
		margin-bottom: var(--bs-gutter-x);
	}

	.character-filterbar {
		gap: 20px;
		margin-bottom: 1rem;

		.input-filter {
			flex-grow: 1;
			width: auto;
		}

		.btn {
			--btn-bg: var(--btn-color);
			flex-shrink: 0;
			font-weight: 700;
			text-transform: uppercase;
		}

		.btn-show-additional-info {
			.fa {
				min-width: 14px;
			}
		}

		.dropdown-menu {
			--bs-dropdown-padding-y: 0;
			--bs-dropdown-border-width: 0;
			--bs-dropdown-border-radius: 3px;
			--bs-dropdown-divider-margin-y: 0;
			--bs-dropdown-item-padding-y: 0.5rem;
			--bs-dropdown-divider-bg: #{transparentize($black, 0.65)};
			overflow: hidden;
		}

		.dropdown-item {
			--btn-darken: 0.2;
			--btn-darken-hover: 0.4;
			--btn-darken-active: 0.5;
			--btn-active-darken: 0.3;
			--bs-dropdown-link-hover-bg: var(--bs-dropdown-link-bg);
			color: $white;
			border-radius: 0;
			font-size: 14px;
			text-transform: uppercase;
			text-align: right;
		}
	}

	.tiles {
		padding: 1rem;
	}

	.character-tile {
		--bs-btn-bg: var(--tile-bg-color, $white);
		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-active-bg: var(--bs-btn-bg);
		--bs-btn-border-width: 0;
		--bs-btn-border-radius: 0;
		position: relative;
		width: 100%;
		height: 120px;
		overflow: hidden;

		&:hover,
		&:focus-visible {
			&::after {
				background-color: transparentize($color: $white, $amount: 0.9);
			}

			img {
				transform: translateY(-5px);
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: transparent;
			pointer-events: none;
			transition: background-color 0.2s ease;
		}

		&.invalid {
			filter: grayscale(1);

			.character-info {
				display: none;
			}
		}

		.text-invalid {
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 3;
			transform: translateY(-50%);
			width: 100%;
			background-color: rgba(62, 62, 62, 0.8);
			color: $white;
			font-weight: 700;
		}

		.character-index {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
			display: inline-block;
			padding: 0 3px;
			background-color: transparentize($black, 0.8);
			color: transparentize($white, 0.5);
			font-size: 12px;
		}
	}
}

.character-topbar {
	gap: 1rem;
	padding: 0.5rem 1rem;
	background-color: rgb(var(--card-color));
	transition: background-color 0.2s ease;

	.btn {
		--btn-bg: var(--btn-color);
		flex-shrink: 0;
	}

	.move-buttons-wrapper {
		display: flex;
		overflow: hidden;
	}

	.move-buttons {
		overflow-x: auto;

		.btn {
			white-space: nowrap;
		}
	}
}

.character-image {
	--opacity: 0.3;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% + 5px);
	object-fit: none;
	opacity: var(--opacity);
	transition-property: transform, opacity;
	transition-duration: 0.2s;
	transition-timing-function: ease;

	@include media-breakpoint-up(lg) {
		--opacity: 1;
	}
}

.character-info {
	position: absolute;
	top: 4px;
	left: 4px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2px;
	color: $white;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;

	.item {
		padding: 1px 4px;
		border-radius: 3px;
	}

	.item-extra {
		background-color: #333;
		text-transform: none;
		padding: 1px 4px;
		border-radius: 3px;
	}

	.grid-difficulty {
		gap: 3px;
	}

	.grid-percent-range {
		background-color: #e03838;
		border: 1px solid $white;
		font-size: 14px;
	}

	.special-info {
		background-color: #f34abf;
	}
}

.very-easy {
	background-color: #8bc34a;
}
.easy {
	background-color: #4caf50;
}
.average {
	background-color: #ffeb3b;
	color: transparentize($black, 0.13);
}
.hard {
	background-color: #ef6c00;
}
.very-hard {
	background-color: #dd2c00;
}

.character-name {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 2;
	width: 100%;
	background-color: transparentize($black, 0.4);
	color: $white;
	padding: 1px 0;
}
