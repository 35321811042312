.modal {
	--bs-modal-border-color: #{$white};
	--bs-modal-border-width: 3px;
	--bs-modal-border-radius: 0;
	--bs-modal-header-padding-x: 0;
	--bs-modal-header-padding-y: 0;
	--bs-modal-width: 600px;
	--bs-modal-margin: 3rem;

	@include media-breakpoint-up(sm) {
		--bs-modal-margin: 4rem;
	}

	.modal-header {
		display: block;
	}

	.btn-close {
		--bs-btn-bg: rgba(0, 0, 0, 0.1);
		--bs-btn-hover-bg: rgba(0, 0, 0, 0.2);
		--bs-btn-active-bg: rgba(0, 0, 0, 0.3);
		--bs-btn-color: #{$white};
		--bs-btn-hover-color: var(--bs-btn-color);
		--bs-btn-active-color: var(--bs-btn-color);
		position: absolute;
		top: -42px;
		border-radius: 50%;
		padding: 0;
		width: 32px;
		height: 32px;
		border: 0;
	}

	.modal-content {
		height: 100%;
		overflow: visible; // to fix arrows + close not showing for modal-staging
	}

	.btn-prev,
	.btn-next {
		--bs-btn-padding-x: 6px;
		--bs-btn-padding-y: 14px;
		--bs-btn-font-size: 1.5rem;
		--bs-btn-border-radius: 0;
		position: absolute;
		top: 50%;
		z-index: 1200;
		transform: translateY(-50%);
		color: $white;
	}

	.btn-prev {
		left: -35px;
	}

	.btn-next {
		right: -35px;
	}
}

.modal-backdrop {
	transition: background-color 0.2s ease;
}

.modal-stage-list {
	--bs-modal-header-padding: 0;
	--bs-modal-border-radius: 0;
	--bs-modal-inner-border-radius: 0;
	--bs-modal-border-width: 3px;

	.modal-header {
		position: relative;
		background-color: var(--btn-color);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: transparentize($black, 0.7);
		}
	}

	.character-info-section {
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: 5px;
		background-color: var(--btn-color);
		color: $white;
		overflow: hidden;
		height: 126px; // fixed height because the content transitions in
	}

	.character-info-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}

	.character-topbar {
		position: relative;
		padding: 0.5rem;

		.btn-group {
			overflow-x: auto;
		}

		.btn {
			--btn-darken: 0.5;
			--btn-darken-hover: 0.6;
			--btn-darken-active: 0.7;
			--btn-active-darken: 0.6;
			font-weight: 700;
		}
	}

	.character-info {
		position: static;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.character-image {
		top: 0;
		left: 0;
		width: auto;
		height: 100%;
		object-fit: contain;
		object-position: left center;
	}

	.character-name {
		position: static;
		padding: 0 6px;
		width: auto;
		border-radius: 3px;
	}

	.more-info {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 150px;
		padding: 10px 10px 10px 0;
		font-weight: 700;

		li {
			display: flex;
			justify-content: space-between;
		}
	}

	.rage-modifier {
		position: relative;
		z-index: 1;
		padding: 1rem 0.25rem;
		background-color: #e8e8e8;

		.rage-modifier-title {
			margin-bottom: 0.4em;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 2px;
		}

		.btn-group {
			flex-wrap: wrap;
			justify-content: center;
			gap: 6px;
		}

		.btn {
			--bs-btn-padding-x: 3px;
			--bs-btn-padding-y: 4px;
			--bs-btn-hover-color: var(--bs-btn-color);
			--bs-btn-focus-color: var(--bs-btn-color);
			--bs-btn-active-bg: #ff6951;
			--bs-btn-active-border-color: var(--bs-btn-active-bg);
			--bs-btn-active-color: #{$white};
			--bs-btn-bg: #{$white};
			--bs-btn-border-color: #bfbfbf;
			--bs-btn-font-size: 12px;
		}
	}

	.legend-keys {
		position: absolute;
		bottom: 0;
		right: -70px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 6px;
		width: 50px;

		.legend-key {
			background: transparentize($white, 0.9);
			display: inline-block;
			padding: 4px 6px;
			border-radius: 4px;

			&.legend-key-keyboard {
				padding: 1px 6px 2px;
			}
		}

		path {
			fill: currentColor;
			transition: fill 0.2s ease;
		}

		.icon-key-escape {
			background-color: currentColor;
			border-radius: 3px;
			font-size: 11px;
			font-weight: 700;
			padding: 0 10px 7px 3px;

			span {
				color: var(--btn-color) !important;
				transition: color 0.2s ease;
			}
		}
	}
}
