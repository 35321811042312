.modal-info {
	.stage {
		.row {
			--bs-gutter-x: 0.75rem;
		}

		tr th {
			padding: 0;
		}
	}
}
