@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slideFromRight {
	0% {
		transform: translateX(var(--amount, 300px));
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes slideFadeFromRight {
	0% {
		transform: translateX(var(--amount, 300px));
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: var(--opacity, 1);
	}
}

.csstrans-fade-enter,
.csstrans-fade-appear {
	opacity: 1;
	animation-name: fadeIn;
	animation-duration: 0.6s;
	animation-fill-mode: both;
	animation-timing-function: ease;
	animation-delay: var(--delay, 0);
}

.csstrans-slideFromRight-enter,
.csstrans-slideFromRight-appear {
	opacity: var(--opacity, 1);
	animation-name: slideFromRight;
	animation-duration: var(--duration, 0.6s);
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-delay: var(--delay, 0);

	@include media-breakpoint-up(md) {
		animation-name: slideFadeFromRight;
	}
}

.spinner-fade-exit {
	opacity: 0;
	animation-name: fadeOut;
	animation-duration: 0.6s;
	animation-fill-mode: both;
}

.spinner-fade-enter,
.spinner-fade-appear {
	opacity: 1;
	animation-name: fadeIn;
	animation-duration: 0.7s;
	animation-fill-mode: both;
	animation-delay: 0.7s;
}
