$red: #d50000;
$orange: #ff5131;

$difficulty-very-easy: #8bc34a;
$difficulty-easy: #4caf50;
$difficulty-average: #ffeb3b;
$difficulty-hard: #ef6c00;
$difficulty-very-hard: #dd2c00;

:root {
	--bs-body-bg: #eee;
	--bs-dark-rgb: 67, 28, 11;
}
