.sidebar {
	position: relative;
	z-index: 1;
	flex-shrink: 0;
	width: 220px;
	background-color: $white;
	overflow: auto;

	ul {
		margin-bottom: 0;
	}

	.sidebar-header {
		width: 100%;
		padding: 20px;
		background: $red;
	}

	.character-header {
		padding: 15px 0 15px 20px;
		text-transform: uppercase;
		font-weight: 700;
	}

	.character-item {
		background-color: rgba(var(--item-color), 0.9);
		transition: background-color 0.2s ease;

		&:hover {
			background-color: rgba(var(--item-color), 1);
		}
	}

	.btn {
		--bs-btn-bg: #{$white};
		--bs-btn-border-width: 0;
		--bs-btn-border-radius: 0;
		--bs-btn-hover-bg: #efefef;
		--bs-btn-active-bg: #e2e2e2;
		--bs-btn-color: #686868;

		position: relative;
		width: 100%;
		text-align: left;
		padding: 10px 10px 10px 20px;
		font-weight: 500;

		&:hover,
		&:focus-visible,
		&:active {
			&::before {
				width: 5px;
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 3px;
			height: 100%;
			background-color: rgb(var(--item-color));
			transition: width 0.2s;
		}
	}
}
