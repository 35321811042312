h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	line-height: var(--bs-body-line-height);
}

.font-weight-normal {
	font-weight: 400;
}

label,
.label {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
}
